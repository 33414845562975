.banner-img {
    object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
  }
  
  /* Media Queries for small screen adjustments */
  @media (max-width: 640px) {
    .banner-img {
      object-fit: contain; /* Keeps the image within the container without cutting it off */
    }
    .banner-container {
      height: 12rem; /* Adjust height for small screens */
      
    }
  }
  
  @media (min-width: 641px) and (max-width: 768px) {
    .banner-container {
      height: 24rem; /* Adjust height for tablets */
    }
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .banner-container {
      height: 28rem; /* Adjust height for small desktops */
    }
  }
  
  @media (min-width: 1025px) {
    .banner-container {
      height: 28rem; /* Adjust height for larger screens */
    }
  }
  