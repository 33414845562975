.btn-custom {
    background-color: #000;
    color: #fff;
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 600;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .btn-custom:hover {
    background-color: #333;
    text-decoration: underline;
    transform: scale(1.05);
  }
  