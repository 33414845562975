@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .animate-fadeIn {
    animation: fadeIn 1s ease-in-out forwards;
  }
  
  .delay-200 {
    animation-delay: 0.2s;
  }
  
  .delay-400 {
    animation-delay: 0.4s;
  }
  

  /* Custom scrollbar-hide class */
.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Hide scrollbar for webkit-based browsers */
}

.scrollbar-hide {
  -ms-overflow-style: none;  /* Hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;  /* Hide scrollbar for Firefox */
}
